@import url("https://fonts.cdnfonts.com/css/a-astro-space");

* {
  outline: none;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "a Astro Space", sans-serif;
  overflow-x: hidden;
}

a,
span {
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
}

p,
ul {
  margin: 0;
  padding: 0;
}

.landing-bg {
  background-image: url("../Assets/imgs/desktop-bg.webp");
  min-height: 100vh;
  padding-top: 35px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 
}

.subscrption-content {
  max-width: 375px;
  margin: auto;
  padding: 0 10px;
  text-align: center;
}
.disable-btn {
  opacity: 0.4 !important;
  cursor: default !important;
}
p.error {
  color: red;
  font-weight: 300;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

.subscrption-content .main-image {
  padding: 10px 0px;
}

.subscrption-content .p-text {
  color: #fff;
}

.subscrption-content span {
  color: rgba(36, 255, 78, 0.96);
  font-size: 25px;
}
.subscrption-content .button {
  padding-top: 30px;
}

.subscrption-content .button .p-button {
  background: linear-gradient(180deg, #01803e 0%, rgba(1, 128, 62, 0.7) 100%);
  color: #fff;
  text-decoration: none;
  border-radius: 10px;
  font-weight: bold;
  width: 100%;
  padding: 10px 20px;
  border: none;
  font-size: 20px;
}

.subscrption-content .sub-text {
  color: rgba(255, 255, 255, 0.9);
  font-size: 11px;

  margin: auto;
  padding: 20px 0px;
}
.subscrption-content .terms a {
  color: #fff;
  font-weight: bold;
}

@media (max-width: 767px) {
  .landing-bg {
    background-image: url("../Assets/imgs/mobie-bg.webp");
    min-height: 100vh;
    padding-top: 17px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
   
  }

  .subscrption-content .main-image {
    padding-top: 43px;
  }
}
